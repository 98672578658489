import request from "@/utils/request.js";

/**
 * 登录
 */
export const handleLogin = (data) => {
  return request.get("/app/wx/user/login", data);
};

export const checkToken = () => {
  return request.get(`/web/users/checkToken`);
};
/**
 * 获取首页数据
 */
export const getIndexData = (data) => {
  return request.get("/web/users/profile", data);
};

/**
 * 获取用户亲密度
 * @param params
 * @returns {*}
 */
export const getUserIntimacy = (params) => {
  return request.get(`/app/wx/user/getUserIntimacy`, params);
};
