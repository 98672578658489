import axios from "axios";
import { closeToast, showFailToast, showLoadingToast } from "vant";

// const URL = "http://162.14.123.184:8082";
const URL = window.location.origin + "/proxy";
let loading;

function startLoading() {
  loading = showLoadingToast({
    message: "Loading...",
    forbidClick: true,
    duration: 0,
    overlay: true,
    iconSize: 32
  });
}

function endLoading() {
  closeToast(loading);
}

const config = {
  // 默认地址
  baseURL: URL,
  // 跨域时候允许携带凭证
  withCredentials: true
};

class RequestHttp {
  // 定义成员变量并指定类型
  service;

  constructor(config) {
    // 实例化axios
    this.service = axios.create(config);

    /**
     * 请求拦截器
     * 客户端发送请求 -> [请求拦截器] -> 服务器
     * token校验(JWT) : 接受服务器返回的token,存储到vuex/pinia/本地储存当中
     */
    this.service.interceptors.request.use(
      (config) => {
        if (config.url.indexOf("/web/users/checkWechat") === -1) {
          startLoading();
        }
        // 请求头添加token
        config.headers["X-Ceiling-token"] = localStorage.getItem("token") || "";
        return {
          ...config
        };
      },
      (error) => {
        // 请求报错
        Promise.reject(error);
      }
    );

    /**
     * 响应拦截器
     * 服务器换返回信息 -> [拦截统一处理] -> 客户端JS获取到信息
     */
    this.service.interceptors.response.use(
      (response) => {
        endLoading();
        const { data, config } = response; // 解构
        if (data.code === 401) {
          // 登录信息失效，应跳转到登录页面，并清空本地的token
          localStorage.setItem("token", "");
          window.location.href = "/login";
          return false;
        }
        // 全局错误信息拦截（防止下载文件得时候返回数据流，没有code，直接报错）
        if (data.code && data.code !== 200) {
          console.log(data);
          showFailToast({ message: data.msg, wordBreak: "normal", iconSize: 36 }); // 此处也可以使用组件提示报错信息
          return false;
        }
        return data;
      },
      (error) => {
        endLoading();
        const { response } = error;
        if (response) {
          this.handleCode(response.status);
        }
      }
    );
  }

  handleCode(code) {
    switch (code) {
      case 401:
        // ElMessage.error('登录失败，请重新登录');
        break;
      default:
        // ElMessage.error('请求失败');
        break;
    }
  }

  // 常用方法封装
  get(url, params) {
    return this.service.get(url, { params });
  }

  post(url, params) {
    return this.service.post(url, params);
  }

  put(url, params) {
    return this.service.put(url, params);
  }

  delete(url, params) {
    return this.service.delete(url, { params });
  }
}

// 导出一个实例对象
export default new RequestHttp(config);
