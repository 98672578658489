<template>
  <Header v-if="!showTabbar" :title="title" />
  <div class="container">
    <router-view />
  </div>
  <tabbar v-show="showTabbar && !hideTabbar"></tabbar>
</template>

<script setup>
  import Tabbar from "@/components/tabbar/tabbar.vue";
  import Header from "@/components/header/header.vue";
  import { onMounted, provide, ref } from "vue";
  import { checkToken, getIndexData } from "@/api";
  import { getQueryVariable } from "@/utils/utils";

  const dcid = ref("");
  provide("dcid", dcid);

  const userProfile = ref("");
  provide("userProfile", userProfile);

  const showTabbar = ref(true);
  const hideTabbar = ref(false);
  provide("showTabbar", showTabbar);
  provide("hideTabbar", hideTabbar);

  const title = ref("");
  provide("title", title);

  onMounted(() => {
    let token = getQueryVariable().dcid;
    if (token) {
      localStorage.setItem("token", token);
    }
    if (window.location.href.indexOf("login") > -1) {
      return;
    }
    checkToken().then((res) => {
      if (res) {
        dcid.value = res.msg;
        localStorage.setItem("dcid", res.msg);
        getIndexData({ dcid: res.msg }).then((v) => {
          userProfile.value = v.data;
        });
      }
    });
  });
</script>

<style lang="scss">
  html,
  body {
    width: 100%;
    height: 100%;
    font-size: 32px;
  }

  #app {
    color: #2c3e50;
    width: 100%;
    height: 100%;
  }

  .container {
    padding: 0 32px 100px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
  }
</style>
