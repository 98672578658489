import {createRouter, createWebHashHistory, createWebHistory} from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/home/index.vue")
  },
  {
    path: "/order",
    name: "order",
    component: () => import("../views/order/index.vue")
  },
  {
    path: "/rank",
    name: "rank",
    component: () => import("../views/rank/index.vue")
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/profile/index.vue")
  },
  {
    path: "/coupon",
    name: "coupon",
    component: () => import("../views/coupon/index.vue")
  },
  {
    path: "/account",
    name: "account",
    component: () => import("../views/account/index.vue")
  },
  {
    path: "/setting",
    name: "setting",
    component: () => import("../views/setting/index.vue")
  },
  {
    path: "/gift",
    name: "gift",
    component: () => import("../views/gift/index.vue")
  },
  {
    path: "/topUp",
    name: "topUp",
    component: () => import("../views/top-up/index.vue")
  },
  {
    path: "/withdraw",
    name: "withdraw",
    component: () => import("../views/withdraw/index.vue")
  },
  {
    path: "/service",
    name: "service",
    component: () => import("../views/service/index.vue")
  },
  {
    path: "/service-add",
    name: "service-add",
    component: () => import("../views/service-add/index.vue")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/index.vue")
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
