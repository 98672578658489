<template>
  <van-nav-bar :title="title" left-arrow @click-left="onClickLeft" />
</template>
<script setup>
  import { inject } from "vue";

  const title = inject("title");
  const showTabbar = inject("showTabbar");

  const onClickLeft = () => {
    showTabbar.value = true;
    window.history.back();
  };
</script>
<style lang="scss" scoped></style>
-
