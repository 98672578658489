<template>
  <div class="nav">
    <van-tabbar v-model="active" active-color="#3cd856">
      <van-tabbar-item v-for="item in list" :name="item.path" icon="home-o" @click="handleLink(item.path)">
        {{ item.text }}
        <template #icon="props">
          <img :src="props.active ? item.selectedIconPath : item.iconPath" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script setup>
  import { onMounted, ref } from "vue";
  import { useRouter } from "vue-router";

  const active = ref("/");
  const router = useRouter();

  onMounted(() => {
    active.value = location.pathname;
  });

  const handleLink = (v) => {
    router.push(v);
  };

  const list = ref([
    {
      path: "/",
      text: "首页",
      iconPath: require("@/assets/images/nav/icon_geren_hui.png"),
      selectedIconPath: require("@/assets/images/nav/icon_geren_lv.png")
    },
    {
      path: "/order",
      text: "订单",
      iconPath: require("@/assets/images/nav/icon_dingdan_hui.png"),
      selectedIconPath: require("@/assets/images/nav/icon_dingdan_lv.png")
    },
    {
      path: "/rank",
      text: "排行",
      iconPath: require("@/assets/images/nav/icon_paihang_hui.png"),
      selectedIconPath: require("@/assets/images/nav/icon_paihang_lv.png")
    },
    {
      path: "/profile",
      text: "我的",
      iconPath: require("@/assets/images/nav/icon_ruzhi_hui.png"),
      selectedIconPath: require("@/assets/images/nav/icon_ruzhi_lv.png")
    }
  ]);
</script>

<style lang="scss" scoped>
  .nav {
    position: fixed;
    bottom: 0;
    height: 100px;
    z-index: 100;

    :deep(.van-tabbar) {
      .van-tabbar-item__text {
        font-size: 32px;
      }
    }
  }
</style>
